module.exports = {
  NODE:'https://dekube-exp-explorer.clive.tk/api/v1',
  TUTORIAL_LINK: "https://docs.testnet.imgauss.com",
  SESSION_STORAGE: {
    currentPair: "current_pair",
    hashedPassword: "hashed_password",
  },
  DEFAULT_TOKEN_PAIR: {
    firstToken: "usdt",
    secondToken: "kube",
  },
  TRANSFER_FEE: 1,
  WITHDRAW_FEE: {
    // usdt: 1.14,
  },
  USDT_BASE_NAMES: {
    usdt: "USDT",
    usde: "USDT",
  },
  USDT_NAMES: {},
  MAX_NUMBER_AFTER_COMMA: 3,
  BASE_TOKEN: "kube",
  EXPLORER_URL:'https://dekube-exp-explorer.clive.tk/#/explorer/',
  TOS_URL:'https://dekube.gitbook.io/docs/terms-of-use',
  STORAGE_ORDERS_KEY: "orders",
  CLOSED_STORAGE_ORDERS_KEY: "orders-closed",
  STORAGE_TR_KEY: "tr",
  TOKENS_INFO: {
    "4": {
      baseName: "kube",
      name: "kube",
      description: "Dekube",
    },
    "1": {
      baseName: "usdt",
      name: "usdt",
      description: "Tether",
    }
  },
};
